<template>
    <div>
        <!-- Banner -->
        <base-inner-banner
            :banner-image="pageBanner"
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->

        <div class="content-wrapper">
            <div class="container">
                <!-- Page Head -->
                <base-page-head :page-head="pagehead"></base-page-head>
                <!-- Page Head -->

                <!-- Page Contents -->

                <div class="page-contents userAccount">
                    <div class="sidenav-wrapper">
                        <aside class="left-col">
                            <account-nav></account-nav>
                        </aside>
                        <div class="right-col">
                            <div class="eventsListing">
                                <h2 class="page__title">
                                    {{ $t('My Events listing') }}
                                </h2>
                                <events-card
                                    v-for="event in events"
                                    :event="event"
                                    :key="event.id"
                                    @showPopUp="showPopUp(event)"
                                ></events-card>
                            </div>
                            <base-loader v-if="eventsLoadding" />
                        </div>
                        <profile-event
                            v-if="Object.keys(event).length !== 0"
                            ref="popUp"
                            :event="event"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/pitch-events-banner-1200X600.png'
import imgLarge from '@/assets/images/banners/pitch-events-banner-1600X700.png'
import imgBig from '@/assets/images/banners/pitch-events-banner-1900X595.png'
import { EventsHelper } from '@/common/crud-helpers/events'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import EventsCard from '@/modules/users/components/events/EventsCard'
import ProfileEvent from '@/modules/users/components/events/ProfileEvent.vue'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    components: {
        EventsCard,
        ProfileEvent,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
            banner: '',
            pagehead: {
                title: '',
                subtitle: '',
                shortdes: '',
            },
            events: [],
            event: {},
            eventsLoadding: true,
            filters: {
                limit: 6,
                offset: 0,
            },
        }
    },
    async created() {
        this.getEvents()
        if (!this.dashboardSettings || !this.dashboardSettings.dashboard_tabs) {
            await SettingsHelper.getDashboardSettings()
        } else if (
            this.dashboardSettings &&
            this.dashboardSettings.dashboard_tabs
        ) {
            this.setTitleAndDescription()
        }
    },
    methods: {
        getEvents() {
            this.eventsLoadding = true
            EventsHelper.list(this.filters)
        },
        getNextData() {
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight >
                    document.documentElement.offsetHeight - 600
                if (bottomOfWindow && !this.eventsLoadding) {
                    let next = this.getNextOffset(this.eventsList.next)
                    if (next) {
                        this.filters.offset = next
                        this.getEvents()
                    }
                }
            }
        },
        showPopUp(event) {
            this.event = event
            this.$bvModal.show('profileEvent')
        },
        setTitleAndDescription() {
            let activeTab = this.dashboardSettings.dashboard_tabs.filter(tab =>
                tab.value.url.includes(this.$route.name),
            )[0]
            this.banner = activeTab.value.banner
            this.pagehead.title = activeTab.value[`title_${this.$i18n.locale}`]
            this.pagehead.shortdes =
                activeTab.value[`description_${this.$i18n.locale}`]
        },
    },
    mounted() {
        this.getNextData()
    },
    computed: {
        ...mapGetters(['eventsList', 'dashboardSettings']),
    },
    watch: {
        eventsList() {
            let allEvents = _.concat(this.events, this.eventsList.results)
            this.events = cloneDeep(allEvents)
            this.eventsLoadding = false
        },
        dashboardSettings() {
            this.setTitleAndDescription()
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/ {
    .listing {
        &__item {
            &--image {
                top: rem(-80px);
            }
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
            &__item {
                &--info {
                    ul {
                        li {
                            &:not(:last-child) {
                                margin-right: 1rem;
                            }
                        }
                    }
                }
                &--image {
                    img {
                        max-width: 200px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        .userAccount {
            .sidenav-wrapper {
                .right-col {
                    padding-left: 4rem !important;
                }
            }
        }
    }
    .content-wrapper {
        .page__head {
            min-height: auto;
        }
    }
}
.sidenav-wrapper {
    margin-top: 0;
    .right-col {
        padding-top: 50px;
        @media screen and (min-width: 1600px) {
            padding-top: 62px;
        }
        @media screen and (max-width: 991px) {
            padding-top: 36px;
        }
    }
}
.page-contents {
    padding-top: rem(104px);
}
.eventsListing {
    margin-top: rem(36.8px);
    @media screen and (max-width: 1600px) {
        margin-top: rem(49.6px);
    }
    @media screen and (max-width: 1450px) {
        margin-top: rem(35.2px);
    }
    @media screen and (max-width: 1199px) {
        margin-top: rem(72px);
    }
    @media screen and (max-width: 991px) {
        margin-top: 0;
    }
}
/deep/ {
    @media screen and (max-width: 1199px) {
        .page-banner {
            --h: 647px;
        }
        .content-wrapper {
            .page__head {
                min-height: 140px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .page-contents {
            padding-top: rem(65px);
        }
        .sidenav-wrapper {
            .right-col {
                .page__title {
                    padding-top: rem(57px);
                    &:after {
                        width: rem(240px);
                    }
                }
            }
        }
        .page-banner {
            --h: 546px;
        }
    }
    @media screen and (max-width: 767px) {
        .page-banner {
            --h: 400px;
        }
        .page-contents {
            padding-top: 0;
        }
        .content-wrapper {
            .page__head {
                min-height: 125px;
            }
        }
    }
}

// ar style

.ar {
    .listing {
        @media screen and (max-width: 1199px) and (min-width: 992px) {
            &__item {
                &--info {
                    ul {
                        li {
                            &:not(:last-child) {
                                margin-left: 1rem;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .eventsListing {
        margin-top: rem(22.4px);
        @media screen and (max-width: 1600px) {
            margin-top: rem(35.2px);
        }
        @media screen and (max-width: 1199px) {
            margin-top: rem(72px);
        }
        @media screen and (max-width: 991px) {
            margin-top: 0;
        }
    }
    @media screen and (max-width: 991px) {
        .userAccount {
            .sidenav-wrapper {
                .right-col {
                    padding-right: 4rem !important;
                    padding-left: 0 !important;
                    padding-top: 34px;
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        .userAccount {
            .sidenav-wrapper {
                .right-col {
                    padding-right: 4rem !important;
                    padding-left: 0;
                }
            }
        }
    }
}
</style>
