export const ideaMembers = {
    methods: {
        ideaMembers(ideaMembers) {
            if (ideaMembers && ideaMembers.length) {
                let m = ''
                ideaMembers.forEach(member => {
                    if (m) {
                        m += ', ' + member.user_detail.display_name
                    } else {
                        m += member.user_detail.display_name
                    }
                })
                return m
            }
        },
    },
}
