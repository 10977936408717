<template>
    <div>
        <!-- ProfileEvent Modal -->
        <b-modal id="profileEvent" hide-footer>
            <div class="profileEvent">
                <p class="title">{{ event.title }}</p>
                <div class="profileEvent-date-contributors">
                    <p class="date">
                        <span>{{ event.event_date | dateDay }}</span>
                        {{ event.event_date | dataMonth }}-
                        {{ event.event_date | dateYear }} -
                        <span class="event">{{ $t('Idea Pitch Event') }}</span>
                    </p>

                    <a
                        v-b-modal.profile
                        class="contributors"
                        data-dismiss="modal"
                        href="#"
                    >
                        {{ $t('no of contributors') }} –
                        <span>
                            {{ event.idea_details.contributor_count }}
                        </span>
                    </a>
                </div>
                <!-- Gallery -->
                <gallery v-if="event.image_field" :event="event"></gallery>
                <!-- Gallery -->
                <div class="eventDetail">
                    <div class="row">
                        <div class="col-sm-6">
                            <ul>
                                <li>
                                    <div class="icon">
                                        <img
                                            loading="lazy"
                                            src="@/assets/images/svg/clock.svg"
                                            alt=""
                                        />
                                    </div>
                                    <i class="ltr">
                                        {{ event.start_time | time }}
                                        {{ event.start_time | ampm }} -
                                        {{ event.end_time | time }}
                                        {{ event.end_time | ampm }}
                                    </i>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div class="icon">
                                        <img
                                            loading="lazy"
                                            src="@/assets/images/svg/location.svg"
                                            alt=""
                                        />
                                    </div>
                                    {{
                                        event.location_type == 'online'
                                            ? $t('Online')
                                            : event.location
                                    }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-6">
                            <ul>
                                <li>
                                    <div class="icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 26 25"
                                        >
                                            <image
                                                id="speaker_copy"
                                                data-name="speaker copy"
                                                xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAZCAYAAAAv3j5gAAABqElEQVRIib2Vu0vDUBSHP0sdCk4OooOOoihaaUUXsX+Ag/iCTjqIo518YEFwCIKTmZydRB1cfYGbggS6CILoZK2bgh1ErQ8uuZDak7Rptf2m9ju595fTnN7UzUdW8MkosAxEgG/gHFgzLePMz/KAMO4sAgfAABAE6oFh4DQRTU67rqggqBNYF9ZZv5WIJptFxeXCUsRLXBcCxoStIKhNGEmrVOUHZYSRPEpVftC+ML/50INSFD9BKWBDWIcF0zLuhS3A73gvAbPAXZ67AiZMyzDF1S6U+sMmgBkddKxdI5ADXvT3KWAVMEzL2BE7aIp1NAJsAmHgCNgD+oEnHRIDDoFdoAvYTkSTg2IXjVdHDcAN0CIq0K39iajANdBjWkbOb0dzHiGKcfVshLVRp8iksEWC4sI4XAKWsCXWBoWxw3uFtfnSp3aTqDiEhfHoSJ3M78LapPQg3AIPomrzKYxHR29Au8ddP+d9jumhKcT1BtTUqZ+pT1T+l5Tq6EIf9dXkNVCDEEXIbRiqQsDn++avZFRQBzBUxRC1d4cKygLpKgalTcvI1uYZAT86RlALZ9nTGgAAAABJRU5ErkJggg=="
                                            />
                                        </svg>
                                    </div>
                                    {{
                                        ideaMembers(event.idea_details.members)
                                    }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="position-relative">
                        <div class="greyBg"></div>
                        <h3>{{ $t('Details') }}</h3>
                        <p>
                            {{ event.description }}
                        </p>
                    </div>
                </div>
            </div>
        </b-modal>
        <!-- profileEvent Modal -->
        <div>
            <b-modal id="profile" hide-footer>
                <div class="profile">
                    <p class="title">
                        {{ $t('no of contributors') }} –
                        {{ event.idea_details.contributor_count }}
                    </p>
                    <idea-contributors
                        :ideaDetails="event.idea_details"
                    ></idea-contributors>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
// import { IdeasHelper } from '@/common/crud-helpers/ideas'
import Gallery from '@/modules/users/components/events/Gallery.vue'
import IdeaContributors from '@/modules/users/components/events/IdeaContributors'
import { ideaMembers } from '@/modules/users/mixins/ideaMembers.js'
// import { mapGetters } from 'vuex'

export default {
    mixins: [ideaMembers],
    props: {
        event: Object,
    },
    components: {
        Gallery,
        IdeaContributors,
    },
}
</script>

<style lang="scss" scoped>
/deep/.modal {
    .modal-dialog {
        .modal-content {
            border-radius: 0px;
            position: relative;
            overflow: hidden;
            padding-bottom: 0;
        }
    }
    .profileEvent {
        .title {
            @extend .gradiantTitle;
        }
        .profileEvent-date-contributors {
            display: flex;
            justify-content: space-between;
            margin-top: rem(25px);
            p {
                margin: 0;
                font-size: rem(15px);
                color: var(--primary);
            }
            .date {
                font-size: rem(14px);
                font-weight: 700;
                direction: ltr !important;
                span {
                    color: var(--primary);
                    font-size: rem(26px);
                    font-weight: 700;
                }
                .event {
                    font-size: rem(20px);
                    font-weight: 600;
                    color: var(--secondary);
                }
            }
            .contributors {
                @extend .date;
                text-transform: uppercase;
                &:hover {
                    text-decoration: none !important;
                }
            }
        }
        .eventDetail {
            padding-top: rem(80px);
            .greyBg {
                z-index: 1;
                top: rem(-40px);
                width: 733px;
            }
            h3 {
                font-size: rem(24px);
                font-weight: 900;
                color: var(--primary);
                text-transform: uppercase;
                margin-bottom: rem(20px);
                position: relative;
                z-index: 2;
            }
            p {
                position: relative;
                z-index: 2;
                font-size: rem(18px);
                color: #2a2a2a;
                line-height: 1.4;
                max-width: 690px;
                margin-bottom: rem(20px);
            }
            .row {
                padding-left: rem(40px);
                padding-bottom: rem(80px);
            }
            ul {
                list-style: none;
                li {
                    font-size: rem(24px);
                    font-weight: 600;
                    color: #e4d700;
                    line-height: 2;
                    display: flex;
                    text-align: left;
                    .icon {
                        margin-right: rem(30px);
                        color: var(--primary);
                        svg {
                            width: 25px;
                            height: auto;
                        }
                        img {
                            width: 25px;
                            height: auto;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 991px) {
            .eventDetail {
                .row {
                    padding-left: 0;
                }
                ul {
                    li {
                        font-size: rem(18px);
                        .icon {
                            svg {
                                width: 18px;
                            }
                            img {
                                width: 18px;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 450px) {
            .profileEvent-date-contributors {
                flex-direction: column;
            }
        }
    }
    .profile {
        padding-bottom: 0;
        .title {
            @extend .gradiantTitle;
            margin-bottom: rem(80px);
            text-transform: capitalize;
        }
        /deep/.scrollWrapper {
            height: 350px;
        }
    }
    &#profile {
        .modal-content {
            padding: rem(30px);
        }
    }
    i {
        font-style: normal;
    }
}

.ar {
    .modal {
        .profileEvent {
            /deep/ {
                .title {
                    &:after {
                        right: 0;
                        left: auto;
                    }
                }
                .profileEvent-date-contributors {
                    .date {
                        direction: ltr;
                    }
                }
            }
            .eventDetail {
                .row {
                    padding-right: rem(40px);
                    padding-left: 0;
                }
                ul {
                    li {
                        text-align: right;
                        .icon {
                            margin-left: rem(30px);
                            margin-right: 0;
                        }
                    }
                }
            }
            @media screen and (max-width: 991px) {
                .eventDetail {
                    .row {
                        padding-right: 0;
                    }
                }
            }
        }
        &#profile {
            .modal-content {
                /deep/.title {
                    &:after {
                        right: 0;
                        left: auto;
                    }
                }
            }
        }
        .profile {
            .title {
                &:after {
                    right: 0;
                    left: auto !important;
                }
            }
        }
    }
}
</style>
