<template>
    <div>
        <div class="eventsGallery">
            <vue-slick-carousel v-bind="slider" ref="carousel">
                <div class="eventsGallery__slide">
                    <div class="eventsGallery__slide--image">
                        <img loading="lazy" :src="event.image_field" alt="" />
                    </div>
                </div>
            </vue-slick-carousel>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
    name: 'eventsGallery',
    props: {
        event: Object,
    },
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            slider: {
                infinite: true,
                draggable: true,
                centerMode: false,
                dots: true,
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.eventsGallery {
    position: relative;
    margin-top: rem(15px);
    $self: &;
    &__slide {
        text-align: center;
        &--image {
            position: relative;
            img {
                width: 100%;
            }
        }
    }
    .slick-current {
        #{$self}__slide {
            &--image {
                @include flex(center, center);
                border: solid 2px #009b74;
            }
        }
    }
}
</style>
