<template>
    <div>
        <base-followers :followers="contributors"></base-followers>
    </div>
</template>

<script>
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

import { IdeasHelper } from '@/common/crud-helpers/ideas'
export default {
    props: {
        ideaDetails: Object,
    },
    data() {
        return {
            contributors: [],
        }
    },
    created() {
        this.getContributors()
    },
    methods: {
        getContributors() {
            IdeasHelper.getContributors({
                uuid: this.uuid(this.$props.ideaDetails.uuid),
                page_size: 12,
            })
        },
    },
    computed: {
        ...mapGetters(['contributorsList']),
    },
    watch: {
        contributorsList() {
            let allContributor = _.concat(
                this.contributors,
                this.contributorsList.results,
            )
            this.contributors = cloneDeep(allContributor)
        },
    },
}
</script>

<style scoped></style>
