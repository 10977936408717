import { render, staticRenderFns } from "./EventsCard.vue?vue&type=template&id=2df28938&scoped=true&"
import script from "./EventsCard.vue?vue&type=script&lang=js&"
export * from "./EventsCard.vue?vue&type=script&lang=js&"
import style0 from "./EventsCard.vue?vue&type=style&index=0&id=2df28938&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2df28938",
  null
  
)

export default component.exports