import { IDEAS, SOCIAL } from '@/modules/ideas/store/actions'

import { ApiServiceHelper } from '../crud-helpers'

export const IdeasHelper = {
    list(params) {
        return ApiServiceHelper.execAction(IDEAS.LIST, params)
    },
    get(params) {
        return ApiServiceHelper.execAction(IDEAS.GET, params)
    },
    getIdeaFormFields() {
        return ApiServiceHelper.execAction(IDEAS.GET_IDEA_FORM_FIELDS)
    },
    create(params) {
        return ApiServiceHelper.execAction(IDEAS.CREATE, params)
    },
    put(params) {
        return ApiServiceHelper.execAction(IDEAS.PUT, params)
    },
    destroy(params) {
        return ApiServiceHelper.execAction(IDEAS.DELETE, params)
    },
    listTrendIdea(params) {
        return ApiServiceHelper.execAction(IDEAS.LIST_TREND, params)
    },
    getTrendIdea(params) {
        return ApiServiceHelper.execAction(IDEAS.GET_TREND, params)
    },
    listIdea(params) {
        return ApiServiceHelper.execAction(IDEAS.LIST_IDEA, params)
    },
    getOpcoIdeaCategory(params) {
        return ApiServiceHelper.execAction(IDEAS.OPCO_IDEA_CATEGORY, params)
    },
    getActiveIdeaCategories(params) {
        return ApiServiceHelper.execAction(IDEAS.ACTIVE_IDEA_CATEGORIES, params)
    },
    getIdea(params) {
        return ApiServiceHelper.execAction(IDEAS.GET_IDEA, params)
    },
    getByIdIdea(id) {
        return ApiServiceHelper.execAction(IDEAS.GET_BY_ID_IDEA, id)
    },
    getBucketCategoriesList(params) {
        return ApiServiceHelper.execAction(
            IDEAS.GET_BUCKET_CATEGORIES_LIST,
            params,
        )
    },
    deleteIdeaDocument(params) {
        return ApiServiceHelper.execAction(IDEAS.DELETE_IDEA_DOCUMENT, params)
    },
    updateIdeaDocument(params) {
        return ApiServiceHelper.execAction(IDEAS.UPDATE_IDEA_DOCUMENT, params)
    },
    updateIdeaMedia(params) {
        return ApiServiceHelper.execAction(IDEAS.UPDATE_IDEA_MEDIA, params)
    },
    postCommentLike(params) {
        return ApiServiceHelper.execAction(SOCIAL.COMMENT_LIKE, params)
    },
    postLike(params) {
        return ApiServiceHelper.execAction(SOCIAL.LIKE, params)
    },
    getLikes(params) {
        return ApiServiceHelper.execAction(SOCIAL.GET_LIKE, params)
    },
    postFollower(params) {
        return ApiServiceHelper.execAction(SOCIAL.FOLLOWER, params)
    },
    getFollowers(params) {
        return ApiServiceHelper.execAction(SOCIAL.GET_FOLLOWER, params)
    },
    postContributor(params) {
        return ApiServiceHelper.execAction(SOCIAL.CONTRIBUTOR, params)
    },
    getContributors(params) {
        return ApiServiceHelper.execAction(SOCIAL.GET_CONTRIBUTOR, params)
    },
    postComment(params) {
        return ApiServiceHelper.execAction(SOCIAL.COMMENT, params)
    },
    getComments(params) {
        return ApiServiceHelper.execAction(SOCIAL.GET_COMMENTS, params)
    },
}
